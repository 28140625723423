<template>
    <div class="w-screen" id="main" style="min-height: 80vh">
      <div class="middle">
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
        <div class="bar bar4"></div>
        <div class="bar bar5"></div>
        <div class="bar bar6"></div>
        <div class="bar bar7"></div>
        <div class="bar bar8"></div>
      </div>
      <div class="text-mid font-bold text-dark text-lg">
        {{this.text}} ...
      </div>
    </div>
</template>

<script>
  
  export default {
      name: 'LoadingDashboard',
      props: [
        "text"
      ]
  }
</script>

<style scoped>
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #000;
  }
  .middle {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .text-mid {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    /* animation: pulse 5s linear infinite; */
    opacity: 0.7;
  }
  .bar {
    width: 10px;
    height: 70px;
    background: #fff;
    display: inline-block;
    transform-origin: bottom center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
    animation: loader 2.2s linear infinite;
  }
  .bar1 {
    animation-delay: 0.1s;
  }
  .bar2 {
    animation-delay: 0.2s;
  }
  .bar3 {
    animation-delay: 0.3s;
  }
  .bar4 {
    animation-delay: 0.4s;
  }
  .bar5 {
    animation-delay: 0.5s;
  }
  .bar6 {
    animation-delay: 0.6s;
  }
  .bar7 {
    animation-delay: 0.7s;
  }
  .bar8 {
    animation-delay: 0.8s;
  }

  @keyframes loader {
    0% {
      transform: scaleY(0.1);
      /* background: #000; */
    }
    50% {
      transform: scaleY(1);
      background: #292d30;
    }
    100% {
      transform: scaleY(0.1);
      background: transparent;
    }
  }

  

</style>