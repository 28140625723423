<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true" @click="closeModal">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div class="inline-block align-bottom bg-white dark:bg-slate-400 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-full lg:w-3/4" :class="{ 'sm:p-6': hasHeader }">
        <div class="" v-if="hasHeader">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-2xl leading-6 font-semibold dark:text-white text-gray-900" id="modal-title">
              {{ title }}
            </h3>
            <div class="mt-2">
              <slot></slot>
            </div>
          </div>
        </div>
        <div class="sm:flex sm:flex-row-reverse mt-3" :class="{ 'sm:justify-end': hasHeader }">
          <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-gray-100 hover:bg-gray-500 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeModal">
            {{ cancelButtonText }}
          </button>
          <button type="button" class="mt-3 w-full inline-flex justify-center  hover:bg-gray-300 rounded-md  shadow-sm px-4 py-3 bg-dark dark:bg-white text-base font-medium text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="submitModal">
            {{ submitButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    submitButtonText: {
      type: String,
      default: 'Submit'
    },
    hasHeader: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    submitModal() {
      this.$emit('submit-modal');
    }
  }
}
</script>
