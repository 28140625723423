import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/proll-add/:id',
    name: 'the_proll_Adder',
    component: () => import('../views/AddProllEntry.vue')
  },
  {
    path: '/business',
    name: 'company',
    component: () => import('../views/CompanyInfo.vue')
  },
  {
    path: '/new-payroll',
    name: 'new_payroll',
    component: () => import('../views/AddPayroll.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterOrg.vue')
  },
  {
    
    path: '/dashboard',
    name: 'homedashboard',
    component: () => import('../views/HomeDashboard.vue')
  },
  {
    path: '/timesheet/:chk_id',
    name: 'timesheet',
    component: () => import('../views/TimeSheet.vue')
  },
  {
    path: '/reports/:year',
    name: 'report',
    component: () => import('../views/Report.vue')
  },
  {
    path: '/reports/:date1/:date2',
    name: 'report_range',
    component: () => import('../views/Report.vue')
  },
  {
    path: '/mailmanual',
    name: 'mailmanual',
    component: () => import('../views/MailManualPcheck.vue')
  },
  {
    path: '/checks',
    name: 'dashboard',
    component: () => import('../views/DashboardPage.vue')
  },
  {
    path: '/checks-batch/:id',
    name: 'chks',
    component: () => import('../views/NewChecks.vue')
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('../views/Employees.vue')
  },
  {
    path: '/employee/:id',
    name: 'employee',
    component: () => import('../views/Employee.vue')
  },
  {
    path: '/payroll/:id',
    name: 'payroll',
    component: () => import('../views/Payroll.vue')
  },
  {
    path: '/payhistory/:id',
    name: 'payhistory',
    component: () => import('../views/PayHistory.vue')
  },
  // {
  //   path: '/review/:id',
  //   name: 'review',
  //   component: () => import('../views/Review.vue')
  // },
  {
    path: '/paystub/:id',
    name: 'paystub',
    component: () => import('../views/PayStub.vue')
  },
  {
    path: '/mailpaystub/:id',
    name: 'mailpaystub',
    component: () => import('../views/MailPaystub.vue')
  },
  {
    path: '/taxes',
    name: 'taxes',
    component: () => import('../views/Taxes.vue')
  },
  {
    path: '/payrolls',
    name: 'payrolls',
    component: () => import('../views/Payrolls.vue')
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import('../views/AccountsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  from
  if (to.name) {
    // Start the route progress bar.
    window.NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  window.NProgress.done()
})

// router.beforeEach((to) => {
//   if (to.name === "login" || to.name === "register" || to.name === "home"){
//     return true
//   }
//   if (localStorage.getItem("access_token")){
//     return true
//   }
//   return {name: "login", query: {"next": to.path}}
// })


export default router
