<template>
  <p class="cursor-pointer mt-48 md:mt-0 text-lg lg:text-2xl font-black text-light dark:text-slate-300 -ml-8 mb-16 text-center" @click="$router.push('/')">P</p>
  <!-- <ul class="text-center mb-4 text-gray-200">
    <li @click="add_user = true" class="mt-5 pl-2 cursor-pointer hover:text-gray-500">
      <i class="fas fa-user text-xl"></i>
      <i class="fa fa-plus text-sm" aria-hidden="true"></i>
    </li> -->
    <!-- <li class="mt-5 cursor-pointer hover:text-gray-500" @click="add_tax = true">
      <i class="fas fa-file-invoice-dollar text-xl"></i>
      <i class="fa fa-plus text-sm" aria-hidden="true"></i>
    </li> -->
  <!-- </ul> -->
  <ul class=" text-center text-gray-200 pt-2 border-t-0 ml-8 border-gray-700">
    <li class="md:hidden" @click="$router.push('/')">
      <a :class="'w-full grid grid-cols-6 text-gray-200 -ml-5  hover:bg-gray-700 pb-4 rounded cursor-pointer'" >
        <span class="cursor-pointer flex flex-rows text-xl bg-opacity-10 rounded-full pl-6 py-5 mr-5 inline-block">
          <i class="fas fa-home text-xl w-4"></i>
        </span>
        <span v-if="thestyle!='width: 6em'" class=" hover:inline pt-6 ml-8">Home</span>
      </a>
      
    </li>
    <li @click="add_user = true">
      <a :class="'w-full grid grid-cols-6 text-gray-200 -ml-5  hover:bg-gray-700 pb-4 rounded cursor-pointer'" >
        <span class="cursor-pointer flex flex-rows text-xl bg-opacity-10 rounded-full pl-6 py-5 mr-5 inline-block">
          <i class="fas fa-user text-xl w-4"></i>
          <i class="fa fa-plus text-sm w-1" aria-hidden="true"></i>
        </span>
        <span v-if="thestyle!='width: 6em'" class=" hover:inline pt-6 ml-8">Add employee</span>
      </a>
      
    </li>
    <li :class="currentTab === 'employees' ? 'text-blue-400' : ''">
      <router-link :class="'w-full grid grid-cols-6 text-gray-200 -ml-5 hover:bg-gray-700 pb-4 rounded cursor-pointer'" to="/employees">
        <i class="cursor-pointer fas fa-user text-xl hover:bg-gray-700 bg-opacity-10 rounded-full px-6 py-5"></i> <br>
        <span v-if="thestyle!='width: 6em'" class=" hover:inline pt-6">Employees</span>
      </router-link>
    </li>
    <li :class="currentTab === 'payrolls' ? 'text-blue-400' : ''"
      class="mt-1"><router-link
        to="/payrolls" :class="'w-full grid grid-cols-6 -ml-5 hover:bg-gray-700 pb-4 rounded cursor-pointer'">
          <i class="cursor-pointer fas text-xl fa-calculator hover:bg-gray-700 bg-opacity-10 rounded-full px-6 py-5"></i>
          <span v-if="thestyle!='width: 6em'" class=" hover:inline pt-6 ml-8">Payrolls</span>
      </router-link></li>
    <li :class="currentTab === 'checks' ? 'text-blue-400' : ''"
      class="mt-1"><router-link :class="'-ml-5 w-full grid grid-cols-6 hover:bg-gray-700 pb-4 rounded cursor-pointer'"
        to="/checks">
        <i class="cursor-pointer fas fa-history text-xl hover:bg-gray-700 bg-opacity-10 rounded-full px-6 py-5"></i>
        <span v-if="thestyle!='width: 6em'" class=" hover:inline pt-6 ml-8">Checks</span>
      </router-link></li>
    <li :class="currentTab === 'reports' ? 'text-blue-400' : ''"
      class="mt-1"><router-link :class="'-ml-5 w-full text-gray-200 grid grid-cols-6 hover:bg-gray-700 pb-4 rounded cursor-pointer'"
        :to="'/reports/'+(new Date()).getFullYear()">
        <i class="cursor-pointer fas fa-clipboard-list text-xl hover:bg-gray-700 bg-opacity-10 rounded-full px-6 py-5"></i>
        <span v-if="thestyle!='width: 6em'" class=" hover:inline pt-6 ml-8">Reports</span>
      </router-link>
    </li>
    <li :class="currentTab === 'company' ? 'text-blue-400' : ''"
      class="mt-1"><router-link :class="'-ml-5 w-full text-gray-200 grid grid-cols-6 hover:bg-gray-700 pb-4 rounded cursor-pointer'"
        :to="'/business'">
        <i class="cursor-pointer fas fa-address-card text-xl hover:bg-gray-700 bg-opacity-10 rounded-full px-6 py-5"></i>
        <span v-if="thestyle!='width: 6em'" class=" hover:inline pt-6 ml-8">Business Info</span>
      </router-link>
    </li>
    <!-- <li :class="currentTab === 'taxes' ? 'text-blue-400' : ''"
        class="mt-1"><router-link
          to="/taxes">
          <i class="cursor-pointer fas fa-file-invoice-dollar text-xl hover:bg-gray-700 bg-opacity-10 rounded-full px-6 py-5"></i>
         </router-link></li> -->

  </ul>
  <ul class="text-center absolute bottom-0 mb-2 border-gray-700">
    <li>
      <a @click.stop="logout" class="mt-2 pl-3" v-if="!no_auth">
        <i class="material-icons text-xl text-gray-200 hover:text-gray-500 cursor-pointer">exit_to_app</i>
      </a>
    </li>
    <li>
      <router-link v-if="me.id" :to="'/employee/'+me.id" class="hidden md:inline hover:text-blue-300 grid grid-cols-5"> <img src="/images/profile.jpg" class="mt-4 ml-4 w-12 h-12 rounded-full"/></router-link>
    </li>
  </ul>
  <ModalComponent v-if="add_user" :submitButtonText="'Add'" :title="'Add Employee'" @close-modal="add_user = false"
    @submit-modal="newUser">
    <span class="text-red-500 text-sm font-light" v-if="error">{{ error }}</span>
    <span class="text-red-500 text-sm font-light" v-if="pass_err">{{ pass_err }}</span>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-3 text-gray-600  my-4">
      <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
        <span class="text-red-600">Admin Password (*)</span>
        <div class="flex flex-rows text-base">
            <span></span>
            <input type="password" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.admin_password">
        </div>
      </div>
      <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
          <span class="text-gray-600">First Name (*)</span>
          <div class="flex flex-rows text-base">
              <span></span>
              <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.first_name">
          </div>
      </div>
      <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
        <span class="text-gray-600">Last Name (*)</span>
        <div class="flex flex-rows text-base">
            <span></span>
            <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.last_name">
        </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
        <span class="text-gray-600">Username (*)</span>
        <div class="flex flex-rows text-base">
            <span></span>
            <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.username">
        </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
        <span class="text-gray-600">Middle Name</span>
        <div class="flex flex-rows text-base">
            <span></span>
            <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.middle_name">
        </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
        <span class="text-gray-600">Position</span>
        <div class="flex flex-rows text-base">
            <span></span>
            <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.position">
        </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
        <span class="text-gray-600">Email (*)</span>
        <div class="flex flex-rows text-base">
            <span></span>
            <input type="email" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.email">
        </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
        <span class="text-gray-600">Home Phone</span>
        <div class="flex flex-rows text-base">
            <span></span>
            <input type="tel" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.home_phone">
        </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
        <span class="text-gray-600">Mobile Phone</span>
        <div class="flex flex-rows text-base">
            <span></span>
            <input type="tel" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.mobile_phone">
        </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">Date Of Birth</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <input type="date" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.dob">
      </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
        <span class="text-gray-600">Hired</span>
        <div class="flex flex-rows text-base">
            <span></span>
            <input type="date" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.hired">
        </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">Routing Number</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <input type="number" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.routing_number">
      </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">Social Security Number</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <input type="number" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.ssn">
        </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">Account Number</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.account_number">
      </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">Routing Number</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.routing_number">
      </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">Address</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <input type="location" class="row-span-3 text-gray-800 bg-gray-200 w-full bg-opacity-0 outline-0" v-model="new_employee.home_phone">
      </div>
    </div>
    <!-- <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">Gender</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <select type="text" class="row-span-3 w-full text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.gender">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
          </select>
      </div>
    </div> -->
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">State</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <select type="text" class="row-span-3 w-full text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.state">
            <option :key="state" v-for="state in states" :value="state">{{state}}</option>
          </select>
      </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">County</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <select type="text" class="row-span-3 w-full text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.county">
            <option :key="county" v-for="county in curr_counties" :value="county">{{county}}</option>
          </select>
      </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">Password (*)</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.password">
      </div>
    </div>
    <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
      <span class="text-gray-600">Confirm Password</span>
      <div class="flex flex-rows text-base">
          <span></span>
          <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="new_employee.pass2">
      </div>
    </div>
    
    </div>
  </ModalComponent>

  <ModalComponent v-if="add_tax" :submitButtonText="'Add'" :title="'Add Tax'" @close-modal="add_tax = false"
    @submit-modal="newTax">
    <span class="text-red-500 text-sm font-light" v-if="tax_error">{{ tax_error }}</span>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 text-gray-600  my-4">
      <div><label class="w-full">Tax Type <input v-model="new_tax.tax_type"
            class="w-full outline-0 bg-gray-100 h-10 text-gray-600 px-2" type="text"></label></div>
      <div><label class="w-full">Work Location <input v-model="new_tax.work_location"
            class="w-full outline-0 bg-gray-100 h-10 text-gray-600 px-2" type="text"></label></div>
      <div><label class="w-full">Percentage Deduction (*) <input v-model="new_tax.percentage"
            class="w-full outline-0 bg-gray-100 h-10 text-gray-600 px-2" type="number" max="99" min="0"></label></div>
      <div><label class="w-full">Name (*) <input v-model="new_tax.name"
            class="w-full outline-0 bg-gray-100 h-10 text-gray-600 px-2" type="text"></label></div>
      <div><label class="w-full">Is Employer Tax <input v-model="new_tax.is_employer_tax"
        class="w-full outline-0 bg-gray-100 h-3 text-gray-600 px-0" type="checkbox"></label></div>
      <div><label class="w-full">Auto Add To All Employees <input v-model="new_tax.auto_add_users"
        class="w-full outline-0 bg-gray-100 h-3 text-gray-600 px-0" type="checkbox"></label></div>
      <div><label class="w-full">Add Income Range<input v-model="inc_range"
        class="w-full outline-0 bg-gray-100 h-3 text-gray-600 px-0" type="checkbox"></label></div>
      <br>

      <div v-if="inc_range" class="col-span-2 grid grid-cols-5">
        <div class="col-span-2"><label class="w-full px-2">Lower Income Range <input v-model="new_tax.income_range1"
          class="w-full outline-0 bg-gray-100 h-10 text-gray-600 px-0" type="number"></label></div>
        <div class="text-center">
          -
        </div>
        <div class="col-span-2"><label class="w-full px-2">Upper Income Range<input v-model="new_tax.income_range2"
            class="w-full outline-0 bg-gray-100 h-10 text-gray-600 px-0" type="number"></label></div>
      </div>
    </div>
      
  </ModalComponent>
</template>

<script>
import get_api from "../service/api.js"
import base_url from "../service/base_url.js"

import ModalComponent from '../components/ModalComponent.vue'
export default {
  name: "HomeMenu",
  props:["thestyle"],
  components: {
    ModalComponent
  },
  created() {
    // alert(this.thestyle)
    // base_url
    this.api = get_api(this.$router)
    if (localStorage.getItem("XPAYROLLaccess_token")){
        this.api.get(`${base_url}/me`).then((resp)=>{
        this.me = resp.data.data
       })
      }
  },
  data() {
    return {
      me: {},
      inc_range: false,
      isOpen: true,
      currentTab: this.$route.path.slice(1),
      add_user: false,
      add_tax: false,
      error: false,
      pass_err: false,
      tax_error: false,
      pass2: null,
      new_employee: {
        "admin_password": "",
        "username": null,
        "first_name": null,
        "last_name": null,
        "middle_name": null,
        "dob": null,
        "gender": "Female",
        "address": null,
        "email": null,
        "home_phone": null,
        "mobile_phone": null,
        "password": null,
        "emp_number": null,
        "position": null,
        "hired": null,
        "pass2": null,
        ssn: null,
        routing_number: null,
        account_number: null,
        timeout: -1,
        state: "",
        county: "",
        curr_counties: [],
        
      },
      new_tax: {
        tax_type: null,
        work_location: null, 
        percentage: null,
        name: null,
        is_employer_tax: false,
        auto_add_users: true,
        income_range1: 0,
        income_range2: 9999999999.9,
      },
      states: [
        "Maryland"
            // 'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
            // 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
            // 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
            // 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
            // 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
            // 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
            // 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
            // 'Wisconsin', 'Wyoming'
        ],
      counties: {Maryland: ['Allegany', 'Anne Arundel', 'Baltimore County', 'Calvert', 'Caroline', 'Carroll', 'Cecil', 'Charles', 'Dorchester', 'Frederick', 'Garrett', 'Harford', 'Howard', 'Kent', 'Montgomery', "Prince George's", "Queen Anne's", "St. Mary's", 'Somerset', 'Talbot', 'Washington', 'Wicomico', 'Worcester', 'Baltimore City']}
    }
  },
  methods: {
    logout(){
      localStorage.removeItem("XPAYROLLaccess_token")
      this.$store.commit('set_access_token', "")
      window.location.reload()
    },
    newUser() {
      this.registerNew()
    },
    newTax() {
      this.tax_error = ""
      let tax = Object.assign({}, this.new_tax) 
      if (!this.new_tax.name || !this.new_tax.percentage ){
        this.tax_error = "Fill in all the fields"
        return false
      }
      tax.percentage = tax.percentage/100
      this.api.post(`${base_url}/tax`, tax).then(
        () => {
          this.add_tax = false
          window.location.href = "/taxes"
        }
      ).catch((err) => {
        console.log(err)
        this.error = "Could not create tax"
      })
    },
    registerNew() {
      this.pass_err = null
      if (!this.validateEmployee(this.new_employee)) {
        return
      }
      if (this.error) {
        return
      }
      this.api.get(base_url+"/employee/username/"+this.new_employee.username).then(()=>{
        if (!this.error){
          this.error="Username already exists"
        }  
      }).catch(()=>{
        
        this.api.post(`${base_url}/register`, this.new_employee).then(
          () => {
            window.location.href = "/employees"
            this.add_user = false
          }
        ).catch((err) => {
          if (err.response.status === 405){
            this.pass_err = "Wrong admin password"
          }
          else {
            this.pass_err = "Could not create user"
          }
          // this.add_user = true
        })
      })
    },
    debounced(new_employee){
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {this.validateEmployee(new_employee)}, 800)
    },
    validateEmployee(new_employee) {
      this.new_employee.emp_number = Math.floor(Math.random() * (449994444444 + 1)) + 222
      // Check for null or undefined object
      if (!new_employee) {
        this.error = "Invalid data"
        return false;
      }
      if (new_employee.admin_password.length < 5) {
        this.error = "Enter admin password to create user"
        return false;
      }
      // Validate first name, last name, and middle name
      if (!new_employee.first_name || (new_employee.first_name && new_employee.first_name.length > 50)) {
        this.error = "First name must be provided and be at most 50 characters"
        return false;
      }
      if (!new_employee.last_name || (new_employee.last_name && new_employee.last_name.length > 50)) {
        this.error = "Last name must be provided and be at most 50 characters"
        return false;
      }
      if (new_employee.middle_name && new_employee.middle_name.length > 50) {
        this.error = "Middle name must be at most 50 characters"
        return false;
      }
      // this.api.get(base_url+"/employee/username/"+this.new_employee.username).then(()=>{
          
      // }).catch(()=>{
      //   if (!this.error){
      //     this.error="Username already exists"
      //   }
      //   // this.error="Username already exists"
      // })
      // Validate date of birth
      if (new_employee.dob && isNaN(Date.parse(new_employee.dob))) {
        this.error = "Invalid date of birth"
        return false;
      }

      // Validate email
      if ( new_employee.email && !/^\S+@\S+\.\S+$/.test(new_employee.email)) {
        this.error = "Invalid email address"
        return false;
      }
     
      if (!new_employee.password || (new_employee.password && new_employee.password.length < 7)) {
        this.error = "Password must be at least 6 characters"
        return false;
      }

      if (new_employee.password !== new_employee.pass2) {
        this.error = "Passwords must match"
        return false;
      }
      if (!new_employee.state){
        this.error = "Select a state"
        return false
      }

      if (!new_employee.username) {
        this.error = "Username requiresd"
        return false;
      }

      // Validate password length
      if (!new_employee.password || (new_employee.password && new_employee.password.length < 7)) {
        this.error = "Password must be at least 6 characters"
        return false;
      }


      // Validate hired date
      if (new_employee.hired && isNaN(Date.parse(new_employee.hired))) {
        return false;
      }
      // this.error = null

      // All validation checks passed, return true
      this.error = null
      return true;
    }

  },
  computed: {
  },
  watch: {
    $route(to) {
      this.currentTab = to.path.slice(1)
    },
    new_employee: {
      handler() {
        // alert(this.new_employee.hired)
        this.debounced(this.new_employee)
        this.curr_counties = this.counties[this.new_employee.state]
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
