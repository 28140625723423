import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
  state () {
    return {
      access_token: '',
    }
  },
  mutations: {
    set_access_token(state, token) {
      state.access_token = token
    }
  }
})

export default store