import axios from 'axios'
import store from '../store';


function get_api(route){
    var api = axios.create({
        headers: {
        "Content-Type": "application/json",
        },
    });

    api.interceptors.request.use(
    (config) => {
        window.NProgress.start()
        const token = store.state.access_token || localStorage.getItem("XPAYROLLaccess_token");
        if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
    );
    api.interceptors.response.use(

        (res) => {
        window.NProgress.done()
        return res;
        },
        async (err) => {
            window.NProgress.done()
            try{
                if (err.response.status === 401 || err.response.status === 422) {
                    console.log("Removing key")
                    store.commit('set_access_token', "")
                    localStorage.removeItem('XPAYROLLaccess_token')
                    window.location.href = "/"
                    // route.push("/")
                    route
                } else if(err.response.status === 403){
                    // window.location.href = "/"
                }
            }
            catch(err) {
                return Promise.reject("Error communicating with server: Server did not respond")
            }
        return Promise.reject(err);
        }
    );
    return api
}
export default get_api;