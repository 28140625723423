<template>
  <div @click="show_search=false" class="shadow-sm px-20 cursor-pointer w-screen bg-navbar dark:bg-slate-700 flex flew-rows h-20 pt-2 lg:w-auto top-2 text-base lg:right-28 animate__animated animate__fadeIn">
    <div :style="'z-index:'+index" class="col-span-2 md:col-span-3 px-8 block">
      <input @click.stop="" @focusin="show_search=true" v-model="search_query" v-if="me.id && me.is_admin" @input="debounceSearch" class="hover:h-13 px-6 dark:bg-slate-300 outline-0 border-b-2 border-gray-400 rounded-sm text-gray-700 dark:text-gray-800  w-full  h-12 focus:h-14" type="text" placeholder="🔍︎  Search Employees" />
    </div> 
    <div class="nav-icons px-4 md:px-2 flex flex-cols pb-2">
      <a v-if="!no_auth && !me.is_admin" @click.stop="logout" class="mt-2 pl-3 float-left text-dark" >
        <i class="material-icons text-xl text-dark hover:text-gray-500 cursor-pointer">exit_to_app</i>
      </a>
      <!-- <a  class="inline mt-2" @click="changeMode"><i class="text-xl dark:text-slate-500 md:text-2xl mr-2 fa rounded-full text-blue-500 material-icons px-4 py-2 hover:bg-blue-100">{{lightMode?'brightness_3':'brightness_high'}}</i></a> -->
      <!-- <router-link v-if="me.id" :to="'/employee/'+me.id" class="hidden md:inline hover:text-blue-300 grid grid-cols-5"> <img src="/images/profile.jpg" class="w-16 h-16 rounded-full"/> </router-link>   -->
       </div>
      <!-- <router-link @click="$router.push('/employee/'+me.id)" v-if="me.id" :to="'/employee/'+me.id" class="flex md:hidden text-blue-400 mr-4 text-center mt-4 text-xl hover:text-blue-300 flex flex-row"> <i class="material-icons" aria-hidden="true">person</i> </router-link> -->

  </div>
  <div style="z-index: 1;" @click="show_search=false;index" class=" fixed top-20 left-0 bg-gray-100 dark:bg-opacity-10 bg-opacity-70 w-screen h-screen pt-8" v-if="show_search">
    <div @click.stop="" class="mx-auto pt-3 md:w-4/5 w-full h-3/5 overflow-y-scroll custom-scrollbar shadow-lg bg-white dark:bg-slate-200 flex flex-rows">
      <div class="md:w-1/3 w-full p-4">
        <h3 class="text-gray-700 font-bold">Employees</h3>
        <div @click="show_search=false;toEmp('/employee/'+emp.id)" v-for="emp in employees" :key="emp.id" class="font-light text-sm py-3 px-2 hover:bg-gray-100 mt-2 cursor-pointer">
          <p class="text-gray-500">{{emp.first_name}} {{emp.last_name}} <span class="text-gray-300">#{{ emp.id }}</span></p>
          <p class="text-gray-400 text-sm">{{emp.email}}</p>
        </div>
      </div>
      <!-- <div class="md:w-1/3 w-full p-4">
        {{ checks }}
      </div> -->
    </div>

  </div>

</template>

<script>
import get_api from "../service/api.js"
import base_url from "../service/base_url.js"

export default {
  name: "NavBar",
  components: {
  },
  created() {
    this.api = get_api(this.$router)
    if (localStorage.getItem("XPAYROLLaccess_token")){
      this.api.get(`${base_url}/me`).then((resp)=>{
      this.me = resp.data.data
    })
    }
  },
  mounted() {
    this.lightMode = localStorage.getItem("proll_11_lightMode") === "true"
    if (!this.lightMode){
        document.querySelector("html").classList.add("dark")
      }
      else {
        document.querySelector("html").classList.remove("dark")
      }

  },
  data(){
    return {
      index: 0, 
      change_pword: false,
      email: "",
      password: "",
      password1: "",
      password2: "",
      lightMode: localStorage.getItem("proll_11_lightMode") === "true",
      status: 0,
      me: {},
      show_search: false,
      search_query: "",
      employees: [],
      checks: []
    }
  },
  methods: {
    logout(){
      localStorage.removeItem("XPAYROLLaccess_token")
      this.$store.commit('set_access_token', "")
      window.location.reload()
    },
    search_employees(){
      this.api.get(base_url+"/employees?s="+this.search_query).then((resp)=>{
        let data = resp.data.data
        this.employees = data
      })
    },
    search_checks(){
      this.api.get(base_url+"/checks?s="+this.search_query).then((resp)=>{
        let data = resp.data.data
        this.checks = data
      })
    },
    changeMode(){
      this.lightMode = !this.lightMode
      localStorage.setItem("proll_11_lightMode", this.lightMode)
    },
    debounceSearch() {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
          this.search_employees();
          // this.search_checks();
          }, 200); 
        },
    toEmp(href){
      window.location.href = href
    }
  },
  computed: {
    no_auth() {
      return localStorage.getItem('XPAYROLLaccess_token')?false:true
    }
  },
  watch:{
    lightMode(new_val){
      if (!new_val){
        document.querySelector("html").classList.add("dark")
      }
      else {
        document.querySelector("html").classList.remove("dark")
      }

    },
    search_query(){
      this.debounceSearch()
    }
  }
};
</script>

<style scoped>

  * {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
  }


 .animate__animated.animate__fadeIn {
    --animate-duration: 2s;
  }

  .animate__animated.animate__fadeIn.auth {
    --animate-duration: 4s;
  }


</style>
