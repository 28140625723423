<template>
    <div class="w-screen" id="main" style="min-height: 80vh">
      <LoginForm v-if="no_auth" />
      <LoadingDashboard :text="'Loading Dashboard'" v-else/>
    </div>
</template>

<script>
  import get_api from "../service/api.js"
  import LoginForm from '../components/LoginForm.vue'
  import LoadingDashboard from '../components/LoadingDashboard.vue'
  import base_url from '../service/base_url.js'
  
  export default {
    components: {
      LoginForm,
      LoadingDashboard
    },
    created() {
      this.api = get_api(this.$router)
      if (!this.no_auth){
        this.api.get(base_url+"/me").then((resp)=>{
          // console.log()
          if (resp.data.data.is_admin){
            this.$router.push("/dashboard")
          } else {
            this.$router.push("/employee/"+resp.data.data.id)
          }
          
        })
      }
    },
    data() {
      return {
      }
    },
    computed: {
      no_auth() {
        return localStorage.getItem('XPAYROLLaccess_token')?false:true
      }
    }
  }
</script>

<style scoped>
</style>
