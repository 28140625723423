<template> 
  <div  class="h-screen w-full bg-text-color dark:bg-slate-700 float-right">
    <nav  class="md:block text-gray-400 w-full md:pt-48 md:pt-8 font-bold h-full center">
      
      <!-- <component :thestyle="thestyle" :is="currentMenu"></component> -->
      <HomeMenu :thestyle="thestyle"></HomeMenu>
    </nav>
  </div>
</template>
  
  <script>
  import get_api from "../service/api.js"
  // import base_url from "../service/base_url.js"
  
  import HomeMenu from '../components/HomeMenu.vue'
  export default {
    name: "SideBar",
    props: [
        "thestyle"
    ],
    components: {
      HomeMenu
    },
    created() {
      // base_url
      this.api = get_api(this.$router)
      if (this.$route.path === "/"){
        this.isOpen = false
      }
    },
    data(){
      return {
        isOpen: true,
        currentMenu: HomeMenu,
        me: {}
      }
    },
    methods: {
      
    },
    computed: {
    }
  };
  </script>
  
<style scoped>
</style>
