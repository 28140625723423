<template>
  <div class="center my-4 pt-10" style="height: 84vh;">
      <div class="bg-white dark:bg-slate-300 shadow-xl bg-opacity-90 text-black px-4 md:px-1 rounded-md w-full md:w-3/5 lg:w-2/5 h-4/5 pt-10 pb-16 animate__animated animate__fadeIn">
      <h1 class="text-4xl text-center mb-10 bigdeal text-gray-700">Welcome</h1>
      <div class="w-3/4 md:w-4/5 mx-auto mt-3 pb-3" @keyup.enter="$refs['loginbtn'].click()">
          <div v-if="failed" class="text-sm text-red-500">
            {{err_msg}}
          </div>
          <!-- <div>
                <label class="font-bold text-sm float-left mb-1 text-gray-700" for="user">Username </label>
                <input name="user" v-model="user" required type="text" class="outline-0 bg-gray-200 w-full h-9 px-2">
          </div> -->
          <span class="text-xs text-red-500 float-left" v-if="invalid_email">Invalid User</span>
          <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
            <span>Username</span>
            <div class="flex flex-rows text-base">
                <span></span>
                <input type="text" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="user">
            </div>
          </div>
          <span class="text-xs text-red-500 float-left" v-if="invalid_pass">Invalid password</span>
          <div class="text-sm grid grid-rows-2 mt-4 px-3 py-2 bg-gray-200 bg-opacity-60 text-gray-400 rounded">
            <span>Password</span>
            <div class="flex flex-rows text-base">
                <span></span>
                <input type="password" class="row-span-3 text-gray-800 bg-gray-200 bg-opacity-0 outline-0" v-model="password">
            </div>
          </div>

          <div class="mt-10 grid grid-cols-2 w-full">
            <div class="float-left"><input v-model="remember_me" type="checkbox" class="outline-0 border h-3 inline-block"> <span class="text-sm text-gray-700">Remember me</span></div>
            <div class="text-right w-full"><a href="/register" class="text-blue-400 text-xs">Sign Up</a></div>
          </div>
        
          <button ref="loginbtn" @click="login" class="mt-6 w-full dark:bg-slate-400 bg-dark px-5 font-bold text-gray-100 text-lg py-2 dark:hover:bg-slate-100 hover:bg-gray-500">Login</button>
          <br><br>
      </div>
      </div>
  </div>
</template>

<script>
import get_api from '../service/api.js'
import base_url from '../service/base_url.js'
export default {
  name: 'LoginForm',
  created(){
      this.api = get_api(this.$router)
  },
  methods: {
      login(){
          this.invalid_pass = false
          this.invalid_email = false
          this.failed = false;
          // let val_mail = /^[^@]+@\w+(\.\w+)+\w$/.test(this.user)
          if (!this.user){
              this.invalid_email = true
              return
          }

          if (!this.password || this.password.length < 6){
              this.invalid_pass = true
              return
          }
          this.api.post(base_url+"/login", {
            "username": this.user,
            "password": this.password,
          }).then((resp)=>{
            let data = resp.data

            this.$store.commit('set_access_token', data["access_token"])
            localStorage.setItem("dboard_user_name", data["name"])
            if (this.remember_me){
                localStorage.setItem("XPAYROLLaccess_token", data["access_token"])
            }
            window.location.reload()
          }).catch((err)=>{
            this.failed = true
            console.log("TEST", err.response)
            this.err_msg = "Username/Password is incorrect"
          })
      }
  },
  data(){
      return {
          user: '',
          password: '',
          failed: false,
          remember_me: true,
          invalid_pass: false,
          invalid_email: false,
          err_msg: ''
      }
  }
}
</script>

<style>

</style>